﻿@mixin webp-background ($path, $extension, $background-size, $repeat, $position) {
    background-size: unquote($background-size);
    background-repeat: unquote($repeat);
    background-position: unquote($position);

    .no-webp & {
        background-image: url("/dist/images/" + $path + "." + $extension);
    }

    .webp & {
        background-image: url("/dist/images/" + $path + ".webp");
    }
}

@mixin webp-background-image ($path, $extension) {
    .no-webp & {
        background-image: url("/dist/images/" + $path + "." + $extension);
    }

    .webp & {
        background-image: url("/dist/images/" + $path + ".webp");
    }
}